import styled from 'styled-components';

import THEME_CONSTS from '~/consts/theme';

export const Item = styled.div`
    cursor: pointer;

    border-top: 3px solid transparent;

    text-decoration: none;

    position: relative;
    display: block;
    float: left;
    height: calc( ${THEME_CONSTS.HEADER_HEIGTH} - 3px );
    min-width: 100px;

    transition: all 0.2s;

    :hover {
        background: ${THEME_CONSTS.ITEM_HOVER_COLOR};
        border-top: 3px solid ${THEME_CONSTS.TOP_LINE_COLOR};
    }

    ${({ hasSubMenu }) => hasSubMenu && `
        :focus {
            background: ${THEME_CONSTS.ITEM_HOVER_COLOR};
            border-top: 3px solid ${THEME_CONSTS.TOP_LINE_COLOR};
        }
    `};

    :hover .nav-item-container-sub,
    :focus .nav-item-container-sub {
        visibility: visible;
        opacity: 1;
    }

`;

export const ContainerSubMenus = styled.div`
    position: absolute;
    left: ${props => props.dropdownMenuPosition == 'left' ? '0' : 'auto'};
    right: ${props => props.dropdownMenuPosition == 'left' ? 'auto' : '0'};
    top: 100%;

    min-width: 220px;

    transition: opacity 0.1s linear;

    background: ${THEME_CONSTS.ITEM_HOVER_COLOR};

    visibility: hidden;
    opacity: 0;
    
    border: 1px solid ${THEME_CONSTS.ITEM_HOVER_COLOR};

    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
`;

export const Container = styled.div`
    color: #ECF0F1 !important;
    text-align: center;
    display: block;
    padding: 10px 4px 0px 4px;
`;

export const Icon = styled.i`
    width: 100%;
    font-size: 18px;
    color: ${props => props.color};

    margin-bottom: 5px;
`;

export const Label = styled.span`
    width: 100%;
    font-size: 12px;
    color: ${props => props.color};
    font-weight: 500;
`;
