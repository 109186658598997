import React, { useState, useEffect, useCallback } from "react";

// SERVICES
import WebsocketService, { WS_EVENT_CONNECTED, WS_EVENT_REQUEST_ONLINE } from '~/services/websockets';

// COMPONENTES DE BASEPAGE
import { BasePageTitle } from "~/components/BasePage";

export default function QTDOnline() {

    const [Quantidade, setQuantidade] = useState(0);

    /**
     * Quando um novo self se conectar.
     */
    const UpdateOnline = useCallback((data) => {
        setQuantidade(data.onlines || 0);
    }, []);

    /**
     * Registra o listener que verifica no servidor ws.
     */
    useEffect(() => {

        WebsocketService.self_checkouts.on(WS_EVENT_CONNECTED, UpdateOnline);

        setTimeout(() => {
            if (Quantidade == 0) {
                WebsocketService.self_checkouts.emit(WS_EVENT_REQUEST_ONLINE);
            }
        }, 100);

        return () => {
            WebsocketService.self_checkouts.off(WS_EVENT_CONNECTED, UpdateOnline);
        }
    }, []);

    return (
        <BasePageTitle>
            Online: {Quantidade}
        </BasePageTitle>
    );
}
