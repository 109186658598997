import React, { useEffect } from 'react'
import ConfigureMaskInput, { GeraDataMaskKey } from '../mask';
import InputBaseNormal from '~/components/InputBase/normal';


const InputNormal = React.forwardRef(({
    mask = '',
    ...props
}, ref) => {

    const key = GeraDataMaskKey();

    useEffect(() => {

        /**
         * Configura as máscaras.
         */
        ConfigureMaskInput(mask, key);

    }, []);

    return (
        <>
            <InputBaseNormal
                ref={ref}

                {...(mask ? { "data-mask-key": key } : {})}
                {...props}
            />
        </>
    )

});

export default InputNormal;
