import styled from "styled-components";

import THEME_CONSTS from "~/consts/theme";

export const BasePageMain = styled.div.attrs((props) => ({
    id: "base_page_main",
}))`
    color: ${THEME_CONSTS.FONT_PRIMARY_COLOR};
    background: ${THEME_CONSTS.BG_COLOR};
    min-height: 100vh;
    min-width: 100%;

    display: flex;
    flex-direction: column;
`;

export const BasePageContainer = styled.div.attrs((props) => ({
    id: "base_page_container",
}))`
    display: flex;
    flex-direction: column;
    flex: 1;

    /* min-height: 100px; */
    padding-top: 2px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    width: calc(100% - (16px * 2));
`;

export const BasePageTitleContainer = styled.div.attrs((props) => ({
    id: "base_page_title_container",
}))`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 8px 0;
    height: 35px;
`;

export const BasePageTitle = styled.span.attrs((props) => ({
    id: "base_page_title",
}))`
    display: inline-block;

    font-size: 24px;
    font-family: inherit;

    color: ${THEME_CONSTS.PAGE_TITLE_FONT_COLOR};

    font-weight: 500;
`;

export const BasePageButtonsContainer = styled.div.attrs((props) => ({
    id: "base_page_button_container",
}))`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 8px 3px;
    height: 35px;

    button:not(:last-child) {
        margin-right: 8px;
    }
`;

export const BasePageTopLineSeparator = styled.div.attrs((props) => ({
    id: "base_page_top_line_separator",
}))`
    height: 1px;
    background-color: #ccc;

    width: calc(100% - (3px * 2));

    margin: 0 3px;
`;

export const BasePageBox = styled.div.attrs((props) => ({
    id: "base_page_box",
}))`
    background: #fff;
    /* min-height: calc(100vh - ${THEME_CONSTS.HEADER_HEIGTH}); */
    flex: 1;

    padding: 20px;

    border-radius: 4px;

    border: 1px solid #dddddd;

    // 100% - padding * 2 - border * 2
    width: calc(100% - (20px * 2) - (1px * 2));
`;
