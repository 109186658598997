import React from 'react'

// STYLED COMPONENTS
import { Item, Container, Icon, Label } from "./styles";

export default function HeaderItem({ children, icon = '', title = '', color = '#fff', onClick = () => { } }) {
    return (
        <Item
            className="nav-item-n1"
            onClick={onClick}
        >
            <Container>
                <Label color={color}>{title}</Label>
                <Icon color={color} className={icon} />
            </Container>

            {children ? children : null}
        </Item>
    )
}
