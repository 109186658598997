import styled from "styled-components";

import THEME_CONSTS from "~/consts/theme";

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

export const ModalWrapper = styled.div`
    width: ${(props) => (props.width > 0 ? `${props.width}px` : props.width)};
    height: ${(props) => (props.height > 0 ? `${props.height}px` : props.height)};

    min-width: ${(props) => (props.minWidth > 0 ? `${props.minWidth}px` : props.minWidth)};
    min-height: ${(props) => (props.minHeight > 0 ? `${props.minHeight}px` : props.minHeight)};

    max-width: ${(props) => (props.maxWidth > 0 ? `${props.maxWidth}px` : props.maxWidth)};
    max-height: ${(props) => (props.maxHeight > 0 ? `${props.maxHeight}px` : props.maxHeight)};

    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: ${(props) => props.background};
    color: #333;
    position: relative;
    z-index: 10;
    border-radius: 6px;
`;

export const ModalContent = styled.div`
    height: 100%;
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    color: ${(props) => props.fontColor};
`;

export const MHeader = styled.div`
    width: calc(100% - 40px); // padding * 2 = 40px
    padding: 20px;
    border-bottom: 4px solid #000;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: ${THEME_CONSTS.HEADER_BG_COLOR};
    color: #fff;
    font-size: 20px;
    font-weight: 500;
`;

export const CloseModalButton = styled.div`
    cursor: pointer;
    position: absolute;

    top: 17px;
    right: 20px;

    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;

    i {
        color: ${(props) => props.color};
        font-size: 32px;
    }
`;
