import styled from 'styled-components';

const TextareaNormal = styled.textarea`
    font-family: 'Roboto Condensed';

    width: calc(100% - 24px - 2px); // padding * 2 = 12px    2px = 1px border * 2
    display: block;
    min-height: 28px;
    padding: 6px 12px;
    font-size: 15px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;

    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);

    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    &:focus{
        border-color: #6c8fb1;
        outline: 0;
    }

    &[disabled] {
        background-color: #eee;
        cursor: not-allowed;
        filter: alpha(opacity=85);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .85;
    }
`;

export default TextareaNormal;