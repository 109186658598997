// SUB
import Lambda from './lambda';
import Modulos from './modulos';
import Produtos from './produtos';
import Usuarios from './usuarios';
import PDVsNFCe from './pdvs_nfce';
import Emitentes from './emitentes';
import SelfCheckouts from './self_checkouts';
import Administradores from './administradores';
import MonitoradoresSelf from './monitoradores_self';

const APIRequestsPainel = {

    modulos: Modulos,

    produtos: Produtos,

    pdv_nfce: PDVsNFCe,

    self_checkouts: SelfCheckouts,

    monitoradores_self: MonitoradoresSelf,

    administradores: Administradores,

    emitentes: Emitentes,

    lambda: Lambda,

    usuarios: Usuarios

}

export default APIRequestsPainel;