/**
 * Firebase
 */
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

/**
 * Configura o Firebase
 */
const app = initializeApp({
    apiKey: "AIzaSyD4kKKuc5WJiRSGR5Uz_m9kkEbEkxdgu3s",
    authDomain: "painel-linvix-sistemas-br.firebaseapp.com",
    projectId: "painel-linvix-sistemas-br",
    storageBucket: "painel-linvix-sistemas-br.appspot.com",
    messagingSenderId: "597362879216",
    appId: "1:597362879216:web:0a4413d5ca31faf1a87035",
    measurementId: "G-J5HNNNT49Y"
});

/**
 * Configura o analytics
 */
const analytics = getAnalytics(app);