import styled from 'styled-components'

import InputBase from '~/components/InputBase';

const InputBaseNormal = styled(InputBase)`
    width: calc(100% - 24px - 2px); // padding * 2 = 12px    2px = 1px border * 2
    height: 24px;
    padding: 6px 12px;
    font-size: 15px;
`;

export default InputBaseNormal;