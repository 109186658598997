import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from "react";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from "~/components/Bootstrap/Col";
import InputGroup from "~/components/Bootstrap/InputGroup";
import Label from "~/components/Bootstrap/Label";

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from "~/components/Inputs/Normal/Normal";
import TextareaNormal from "~/components/Textareas/Normal";
import SelectNormal from "~/components/Selects/Normal/Normal";

// BUTONS
import ButtonPrimary from "~/components/Buttons/Normal/Primary";
import ButtonSecondary from "~/components/Buttons/Normal/Secondary";
import ButtonDanger from "~/components/Buttons/Normal/Danger";

// UTILS
import numeral from "~/utils/numeral";
import Utils from "~/utils";
import NotificacaoUtil from "~/utils/notificacao";

// SERVICES
import WebsocketService, { WS_EVENT_REQUEST_SYNC_ACTION } from "~/services/websockets";
import APIRequests from "~/services/requests/painel";

const ModalSyncAction = forwardRef(({ onConfirm = () => {} }, ref) => {
    const [isVisible, setVisible] = useState(false);

    const [Serial, setSerial] = useState("");
    const [Action, setAction] = useState("start");

    const onRequestConfirm = async () => {
        try {
            WebsocketService.pdvs_nfce.emit(WS_EVENT_REQUEST_SYNC_ACTION, {
                action: Action,
                serial: Serial,
            });

            NotificacaoUtil.success({
                msg: "Instrução enviada",
                timeout: 3500,
            });

            setVisible(false);
        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message,
                timeout: 3500,
            });
        }
    };

    const onRequestOpen = async (serial = false) => {
        let visible = true;

        if (serial) {
            setSerial(serial);
        }

        setVisible(true);
    };

    const onRequestClose = () => {
        setVisible(false);

        // reseta o estado
        setSerial("");
    };

    const onKeyDown = (e) => {
        // pega o código pressionado
        const code = e.which !== false ? e.which : e.keyCode;

        // stop events
        if (code == 13 || code == 27) {
            e.preventDefault();
        }

        if (code == 13) {
            onRequestConfirm();
        }

        if (code == 27) {
            onRequestClose();
        }
    };

    /**
     * Passa a função de buscar para fora do input via ref.
     */
    useImperativeHandle(ref, () => ({
        open: onRequestOpen,
        close: onRequestClose,
    }));

    return (
        <Modal
            isVisible={isVisible}
            setVisible={onRequestClose}
            closeButtonVisible={false}
            closeOnClickOutside={false}
            width={420}
            height={Serial ? 315 : 255}>
            <Main>
                <ModalHeader>Sincronismo do PDV NFCe</ModalHeader>

                <Content>
                    <Row>
                        {Serial && (
                            <ColSm12>
                                <Label>Serial</Label>
                                <InputNormal disabled value={Serial} />
                            </ColSm12>
                        )}
                    </Row>

                    <Row>
                        <ColSm12>
                            <Label>Ação</Label>
                            <SelectNormal value={Action} onKeyDown={onKeyDown} onChange={(e) => setAction(e.target.value)}>
                                <option value="start">Iniciar</option>
                                <option value="stop">Parar</option>
                            </SelectNormal>
                        </ColSm12>
                    </Row>

                    <Footer>
                        <ButtonSecondary onClick={onRequestClose}>Fechar</ButtonSecondary>

                        <ButtonDanger onClick={onRequestConfirm}>Executar</ButtonDanger>
                    </Footer>
                </Content>
            </Main>
        </Modal>
    );
});

export default ModalSyncAction;
