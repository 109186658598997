import styled from 'styled-components';

import ButtonBase from '~/components/ButtonBase';

export default styled(ButtonBase)`
    font-size: 14px;
   
    border: 0;
    padding: 0;
    padding: 6px 18px;
    border-radius: 4px;

    background: linear-gradient(transparent, rgba(0, 0, 0, 0.1)) top/100% 800%;
`;