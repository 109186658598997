import React, { useState, useEffect, useRef, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests/painel";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/Table';

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";

export default function PageMonitoradoresSelfCheckouts() {

    const RefModalCad = useRef(null);

    /**
     * Lista de data
     */
    const [data, setData] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    /**
     * Carrega os data da
     */
    const goLoad = async () => {
        const response = await APIRequests.monitoradores_self.listar(Pesquisa);

        if (response.results) {
            setData(response.results);
        }
    }

    /**
     * Abre o modal para inserir.
     */
    const onRequestAdd = () => {
        RefModalCad?.current?.open();
    }

    /**
     * Abre o modal para editar.
     */
    const onRequestEdit = (uuid) => {
        RefModalCad?.current?.open(uuid);
    }

    /**
     * Ao confirmar o cadastro ou edição do .
     */
    const onConfirmCadEdit = () => {
        goLoad();
    }

    /**
     * Carrega os data a cada pesquisa
     */
    useEffect(() => {
        if (Pesquisar === true) {
            goLoad();
        }
    }, [Pesquisa, Pesquisar]);

    /**
     * Carrega a listagem de usuários quando carregar a página.
     */
    useEffect(() => {
        goLoad();
    }, []);

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Monitoradores Self Checkouts
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    <ButtonPrimary onClick={onRequestAdd}>
                        Adicionar
                    </ButtonPrimary>
                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"
                                onChange={(e) => setPesquisar(true)}
                                onSearch={(text) => setPesquisa(text)}
                            />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={40}>
                                        <center>#</center>
                                    </Th>
                                    <Th>Nome</Th>
                                    <Th width={220}>E-mail</Th>
                                    <Th width={115}>Celular</Th>
                                    <Th width={100}>Situação</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data.map((prod) => {
                                    return (
                                        <Tr key={prod.uuid}>
                                            <Td width={40}>
                                                <center>
                                                    <ButtonPrimary onClick={() => onRequestEdit(prod.uuid)}>
                                                        <i className="fas fa-pencil" />
                                                    </ButtonPrimary>
                                                </center>
                                            </Td>
                                            <Td>{prod.nome}</Td>
                                            <Td width={220}>{prod.email}</Td>
                                            <Td width={115}>{prod.celular}</Td>
                                            <Td width={100} style={{ textTransform: 'capitalize' }}>{prod.situacao}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao
                ref={RefModalCad}
                onConfirmCadEdit={onConfirmCadEdit}
            />

        </BasePageMain >
    );
}
