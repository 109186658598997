import React from 'react'

// STYLED COMPONENTS
import { Item, Container } from "./styles";

import LogoHeader from '~/images/logo-header.png'

export default function HeaderLogo({
    children,
    onClick = () => { }
}) {
    return (
        <Item
            className={`nav-item-logo`}
            tabIndex="-1"
            onClick={(e) => {
                e.target.focus();
                onClick();
            }}
        >

            <Container>
                <img src={LogoHeader} />
            </Container>

        </Item >
    )
}
