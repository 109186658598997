import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/painel";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

// COMPONENTES DE BASEPAGE
import {
    BasePageMain,
    BasePageContainer,
    BasePageBox,
    BasePageTitleContainer,
    BasePageTitle,
    BasePageButtonsContainer,
    BasePageTopLineSeparator,
} from "~/components/BasePage";

// TABLE
import Table from "~/components/TableR";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from "~/components/Buttons/Small/Primary";
import ButtonDanger from "~/components/Buttons/Normal/Danger";
import ButtonSuccess from "~/components/Buttons/Normal/Success";

// COMPONENTES INTERNOS
import OnlineOffline from "./components/OnlineOffline";
import QTDPDVsOnline from "./components/PDVsOnline";

import ModalLiberateSetupPDVNFCe from "./components/ModalLiberateSetup";
import ModalCadastroEdicao from "./components/ModalCadEdit";
import ModalBlock from "./components/ModalBlock";
import ModalUnblock from "./components/ModalUnblock";
import ModalSyncAction from "./components/ModalSyncAction";

// UTILS
import Utils from "~/utils";

export default function PageCadastroPDVsNFCe() {
    const RefModalCad = useRef(null);
    const RefModalBlock = useRef(null);
    const RefModalUnblock = useRef(null);
    const RefModalLiberateSetup = useRef(null);
    const RefModalSyncAction = useRef(null);

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de PDVsNFCe
     */
    const [PDVsNFCe, setPDVsNFCe] = useState([]);

    const [Pesquisa, setPesquisa] = useState("");

    const [Recarregar, setRecarregar] = useState(new Date());

    /**
     * Carrega os PDVsNFCe da
     */
    const goLoadPDVsNFCe = async () => {
        const PDVsNFCe = await APIRequests.pdv_nfce.listar({
            query: Pesquisa,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted,
        });

        if (PDVsNFCe.results) {
            setPDVsNFCe(PDVsNFCe.results);
            setNavPageCount(PDVsNFCe.metadata.page.total);
            setNavTotalRows(PDVsNFCe.metadata.total);
        }
    };

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, []);

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddPDV = () => {
        RefModalCad?.current?.open();
    };

    /**
     * Abre o modal para editar.
     */
    const onRequestEditPDV = (uuid) => {
        RefModalCad?.current?.open(uuid);
    };

    /**
     * Abre o modal para controlar o sincronismo
     */
    const onRequestControlSync = (serial = "") => {
        RefModalSyncAction?.current?.open(serial);
    };

    /**
     * Abre o modal de bloqueio
     */
    const onRequestBlock = (uuid) => {
        RefModalBlock?.current?.open(uuid);
    };

    /**
     * Abre o modal de desbloqueio
     */
    const onRequestUnblock = (uuid) => {
        RefModalUnblock?.current?.open(uuid);
    };

    /**
     * Abre o modal para liberar a instalação.
     */
    const onRequestLiberateSetup = (uuid) => {
        RefModalLiberateSetup?.current?.open(uuid);
    };

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadPDVsNFCe();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
    }, [Pesquisa, NavLimitPerPage, NavCurrentPage, NavSorted, Recarregar]);

    /**
     * Refresh automatico a cada 5 segundos
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setRecarregar(new Date());
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(
        () => [
            {
                accessor: "uuid",
                width: 90,
                disableSortBy: true,
                Header: (data) => {
                    return <center>#</center>;
                },
                Cell: ({ value, row }) => {
                    const { original } = row;
                    return (
                        <center>
                            <ButtonPrimary disabled={!AuthService.checkPermission("pdvs_nfce", "editar")} onClick={() => onRequestEditPDV(value)}>
                                <i className="fas fa-eye" />
                            </ButtonPrimary>

                            <ButtonPrimary
                                disabled={!AuthService.checkPermission("pdvs_nfce", "editar")}
                                onClick={() => onRequestControlSync(original.serial)}>
                                <i className="fas fa-sync" />
                            </ButtonPrimary>

                            {["instalado", "bloqueado", "bloqueio agendado"].includes(original.situacao) && (
                                <>
                                    {original.bloqueio == true ? (
                                        <ButtonSuccess
                                            disabled={!AuthService.checkPermission("pdvs_nfce", "desbloquear")}
                                            onClick={() => onRequestUnblock(value)}>
                                            <i className="fas fa-unlock-alt" />
                                        </ButtonSuccess>
                                    ) : (
                                        <ButtonDanger
                                            disabled={!AuthService.checkPermission("pdvs_nfce", "bloquear")}
                                            onClick={() => onRequestBlock(value)}>
                                            <i className="fas fa-lock-alt" />
                                        </ButtonDanger>
                                    )}
                                </>
                            )}
                        </center>
                    );
                },
            },
            {
                Header: "Serial",
                accessor: "serial",
                width: 120,
            },
            {
                Header: "Operador Logado",
                accessor: "operador_logado",
            },
            {
                Header: "Versão",
                accessor: "versao",
                width: 70,
            },
            {
                Header: "Emitente",
                accessor: "emitente",
                width: 130,
            },
            {
                Header: "Emitente Nome",
                accessor: "emitente_nome",
            },
            {
                Header: "Última abertura",
                accessor: "data_ultima_abertura",
                width: 120,
            },
            {
                Header: "PDC PayGo",
                accessor: "pdc_paygo",
                width: 100,
            },
            {
                Header: "Terminal",
                accessor: "terminal",
                width: 120,
            },
            {
                Header: "Situação",
                accessor: "situacao",
                width: 90,
                Cell: ({ value, row }) => {
                    const { original } = row;

                    const permission = AuthService.checkPermission("pdvs_nfce", "liberar_instalacao");

                    return (
                        <div
                            style={{
                                textTransform: "capitalize",
                                cursor: original.situacao == "instalado" && permission ? "pointer" : "initial",
                            }}
                            {...(original.situacao == "instalado" && permission ? { onClick: () => onRequestLiberateSetup(original.uuid) } : {})}>
                            <OnlineOffline online={original.online} />
                            {original.situacao}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const TableInitialSortBy = [
        {
            id: "id",
            desc: true,
        },
    ];

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer style={{ justifyContent: "space-between" }}>
                    <BasePageTitle>PDVs NFC-e</BasePageTitle>
                    <QTDPDVsOnline />
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer style={{ justifyContent: "space-between" }}>
                    {AuthService.checkPermission("pdvs_nfce", "adicionar") && ( //
                        <ButtonPrimary onClick={onRequestAddPDV}>Adicionar</ButtonPrimary>
                    )}

                    {AuthService.checkPermission("pdvs_nfce", "adicionar") && ( //
                        <ButtonPrimary onClick={() => onRequestControlSync()}>Controlar sincronismo</ButtonPrimary>
                    )}
                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput inputType="small" onSearch={(text) => setPesquisa(text)} />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table
                            nostriped
                            initialSortBy={TableInitialSortBy}
                            pageIndex={NavCurrentPage - 1}
                            pageCount={NavPageCount}
                            totalRows={NavTotalRows}
                            initialPageSize={20}
                            data={PDVsNFCe}
                            columns={TableColumns}
                            onChangeNav={onTableNavChange}
                        />

                        {/* <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={70}>
                                        <center>#</center>
                                    </Th>
                                    <Th width={110}>Serial</Th>
                                    <Th width={100}>Versão</Th>
                                    <Th>Emitente</Th>
                                    <Th width={170}>Dt.última abertura</Th>
                                    <Th width={140}>Situação</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {PDVsNFCe.map((pdv) => {
                                    return (
                                        <Tr
                                            key={pdv.uuid}
                                            style={{
                                                color: pdv.online === true ? 'green' : '',
                                            }}
                                        >
                                            <Td width={70}>
                                                <center>
                                                    <ButtonPrimary onClick={() => onRequestEditPDV(pdv.uuid)}>
                                                        <i className="fas fa-eye" />
                                                    </ButtonPrimary>

                                                    {[
                                                        'instalado',
                                                        'bloqueado',
                                                        'bloqueio agendado',
                                                    ].includes(pdv.situacao) && (
                                                            <>
                                                                {pdv.bloqueio == true ? (
                                                                    <ButtonSuccess onClick={() => onRequestUnblock(pdv.uuid)}>
                                                                        <i className="fas fa-unlock-alt" />
                                                                    </ButtonSuccess>
                                                                ) : (
                                                                    <ButtonDanger onClick={() => onRequestBlock(pdv.uuid)}>
                                                                        <i className="fas fa-lock-alt" />
                                                                    </ButtonDanger>
                                                                )}
                                                            </>
                                                        )}
                                                </center>
                                            </Td>
                                            <Td width={110}>{pdv.serial}</Td>
                                            <Td width={100}>{pdv.versao}</Td>
                                            <Td>{pdv.emitente}</Td>
                                            <Td width={170}>{pdv.data_ultima_abertura}</Td>
                                            <Td
                                                width={140}
                                                style={{
                                                    textTransform: 'capitalize',
                                                    cursor: pdv.situacao == 'instalado' ? 'pointer' : 'initial',
                                                }}

                                                {...(pdv.situacao == 'instalado' ? { "onClick": () => onRequestLiberateSetup(pdv.uuid) } : {})}
                                            >
                                                <OnlineOffline online={pdv.online} />
                                                {pdv.situacao}
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table> */}
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao ref={RefModalCad} onConfirmCadEdit={goLoadPDVsNFCe} />

            <ModalBlock ref={RefModalBlock} onConfirm={goLoadPDVsNFCe} />

            <ModalUnblock ref={RefModalUnblock} onConfirm={goLoadPDVsNFCe} />

            <ModalLiberateSetupPDVNFCe ref={RefModalLiberateSetup} onConfirm={goLoadPDVsNFCe} />

            <ModalSyncAction ref={RefModalSyncAction} />
        </BasePageMain>
    );
}
