// NAVBAR
const HEADER_HEIGTH = "62px";
const HEADER_MBOTTOM = "0px";
const OFF_CONTAINER_HEIGHT = "30px";
const HEADER_BG_COLOR = '#333';
const ITEM_HOVER_COLOR = '#555';
const TOP_LINE_COLOR = '#a9a9a9';

const BG_COLOR = '#eceff1';

const FONT_PRIMARY_COLOR = '#4a4343';
const PAGE_TITLE_FONT_COLOR = '#444';

const BUTTON_PRIMARY_BG_COLOR = '#444';
// const BUTTON_PRIMARY_BG_COLOR = '#6194B1';
const BUTTON_PRIMARY_FONT_COLOR = '#fff';

const BUTTON_SECONDARY_BG_COLOR = '#d9d9d9';
const BUTTON_SECONDARY_FONT_COLOR = '#5a5a5a';

const BUTTON_DANGER_BG_COLOR = '#c33535';
const BUTTON_DANGER_FONT_COLOR = '#fff';

const BUTTON_SUCCESS_BG_COLOR = '#209320';
const BUTTON_SUCCESS_FONT_COLOR = '#fff';

const THEME_CONSTS = {

    HEADER_HEIGTH,
    HEADER_MBOTTOM,
    OFF_CONTAINER_HEIGHT,
    HEADER_BG_COLOR,
    ITEM_HOVER_COLOR,
    TOP_LINE_COLOR,


    BG_COLOR,
    FONT_PRIMARY_COLOR,
    PAGE_TITLE_FONT_COLOR,

    BUTTON_PRIMARY_BG_COLOR,
    BUTTON_PRIMARY_FONT_COLOR,

    BUTTON_SECONDARY_BG_COLOR,
    BUTTON_SECONDARY_FONT_COLOR,

    BUTTON_DANGER_BG_COLOR,
    BUTTON_DANGER_FONT_COLOR,

    BUTTON_SUCCESS_BG_COLOR,
    BUTTON_SUCCESS_FONT_COLOR,

}

export default THEME_CONSTS;