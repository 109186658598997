import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import debounce from 'lodash/debounce';

// COMPONENTE
import ReactAsyncSelect from '~/components/ReactSelect/async';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import InputGroup from '~/components/Bootstrap/InputGroup';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';

// UTILS
import numeral from '~/utils/numeral';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import APIRequests from "~/services/requests/painel";
import AuthService from '~/services/auth';

const ModalCadastroEdicao = forwardRef(
    ({
        onConfirmCadEdit = () => { }
    }, ref) => {

        const [Modulos, setModulos] = useState([]);

        const [isVisible, setVisible] = useState(false);

        const [UUID, setUUID] = useState('');
        const [Nome, setNome] = useState('');
        const [Email, setEmail] = useState('');
        const [Senha, setSenha] = useState('');
        const [Celular, setCelular] = useState('');
        const [Situacao, setSituacao] = useState('ativo');
        const [TodosEmitentes, setTodosEmitentes] = useState(0);

        const [Emitentes, setEmitentes] = useState([]);

        const [Permissoes, setPermissoes] = useState([]);

        // /**
        //  * Checkbox que seleciona o grupo
        //  */
        // const onChangeCheckboxGroup = (grupo, event) => {

        //     /**
        //      * Checkbox está marcado ou não?
        //      */
        //     const checked = event.target.checked;

        //     /**
        //      * Permissões geradas no final
        //      */
        //     const permissoes_geradas = [];

        //     for (const modulo of grupo.modulos) {
        //         for (const permissao of modulo.permissoes) {
        //             permissoes_geradas.push({
        //                 modulo: modulo.modulo,
        //                 permissao: permissao.permissao,
        //             });
        //         }
        //     }

        //     /**
        //      * Atualiza as permissões do usuário
        //      */
        //     setPermissoes(old => {

        //         let permissions = [...old];

        //         if (checked) {
        //             permissions.push(...permissoes_geradas);
        //         } else {
        //             for (const perm of permissoes_geradas) {
        //                 permissions = permissions.filter((p, i) => (
        //                     p.modulo != perm.modulo &&
        //                     p.permissao != perm.permissao
        //                 ));
        //             }
        //         }

        //         return permissions;

        //     });

        // };

        // /**
        //  * Checkbox que seleciona o módulo
        //  */
        // const onChangeCheckboxModule = (modulo, event) => {

        //     /**
        //      * Checkbox está marcado ou não?
        //      */
        //     const checked = event.target.checked;

        //     /**
        //      * Permissões geradas no final
        //      */
        //     const permissoes_geradas = [];

        //     for (const permissao of modulo.permissoes) {
        //         permissoes_geradas.push({
        //             modulo: modulo.modulo,
        //             permissao: permissao.permissao,
        //         });
        //     }

        //     /**
        //      * Atualiza as permissões do usuário
        //      */
        //     setPermissoes(old => {

        //         let permissions = [...old];

        //         if (checked) {
        //             permissions.push(...permissoes_geradas);
        //         } else {
        //             for (const perm of permissoes_geradas) {
        //                 permissions = permissions.filter((p, i) => !(p.modulo == perm.modulo && p.permissao == perm.permissao));
        //             }
        //         }

        //         return permissions;

        //     });

        // };

        // /**
        //  * Checkbox que seleciona o grupo
        //  */
        // const onChangeCheckboxAccess = (modulo, acesso, event) => {

        //     /**
        //      * Checkbox está marcado ou não?
        //      */
        //     const checked = event.target.checked;

        //     /**
        //      * Atualiza as permissões do usuário
        //      */
        //     setPermissoes(old => {

        //         let permissions = [...old];

        //         if (checked) {
        //             permissions.push({
        //                 modulo: modulo.modulo,
        //                 permissao: acesso.permissao,
        //             });
        //         } else {
        //             permissions = permissions.filter((p, i) => !(p.modulo == modulo.modulo && p.permissao == acesso.permissao));
        //         }

        //         return permissions;

        //     });

        // };


        const onRequestConfirm = async () => {

            try {

                if (!Nome) {
                    throw new Error('Informe o nome');
                }

                if (!Email) {
                    throw new Error('Informe o e-mail');
                }

                if (!Senha) {
                    throw new Error('Informe a senha');
                }

                if (!Celular) {
                    throw new Error('Informe o celular');
                }

                const objeto_enviar = {
                    nome: Nome,
                    email: Email,
                    senha: Senha,
                    celular: Celular,
                    situacao: Situacao,
                    permissoes: Permissoes,
                    todos_emitentes: TodosEmitentes == 1 ? true : false,
                    emitentes: Emitentes.map((emit) => emit.uuid)
                }

                let retorno_api = {};
                if (UUID) {
                    retorno_api = await APIRequests.monitoradores_self.atualizar(UUID, objeto_enviar);
                } else {
                    retorno_api = await APIRequests.monitoradores_self.adicionar(objeto_enviar);
                }

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: UUID ? `Monitorador atualizado com sucesso` : 'Monitorador cadastrado com sucesso',
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirmCadEdit();

                } else {
                    NotificacaoUtil.error({
                        msg: UUID ? `Erro ao atualizar o monitorador` : `Erro ao cadastrar o monitorador`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestOpen = async (uuid = false) => {
            let visible = true;

            // /**
            //  * Busca os dados do usuário
            //  */
            // const user = await AuthService.getUserData();

            // /**
            //  * Carrega a lista de módulos do sistema.
            //  */
            // const lista_modulos = await APIRequests.modulos.listar();

            // if (lista_modulos.length > 0) {

            //     // /**
            //     //  * Remove modulos filtrados para usuários sem permissão
            //     //  */
            //     // if (user?.master === false) {
            //     //     lista_modulos = lista_modulos.filter((m) => (m.grupo != 'configuracoes'));
            //     // }

            //     setModulos(lista_modulos);
            // } else {

            //     visible = false;

            //     NotificacaoUtil.error({
            //         msg: 'Falha ao carregar os módulos do sistema',
            //     });

            // }

            if (uuid) {

                const dados_monitorador = await APIRequests.monitoradores_self.obter(uuid);

                if (dados_monitorador.uuid) {

                    setUUID(dados_monitorador.uuid);
                    setNome(dados_monitorador.nome);
                    setEmail(dados_monitorador.email);
                    setSenha(dados_monitorador.senha);
                    setCelular(dados_monitorador.celular);
                    setSituacao(dados_monitorador.situacao);
                    setTodosEmitentes(dados_monitorador.todos_emitentes ? 1 : 0);

                    // monta os dados do emitente
                    setEmitentes(
                        [...(dados_monitorador?.emitentes || [])]?.map((reg) => ({
                            // 52 é o tamanho que fica em uma linha
                            label: `${reg?.dados_emitente?.nome?.substring(0, 40)} - ${reg?.dados_emitente?.cpf_cnpj}`,
                            value: reg?.dados_emitente?.uuid,
                            ...reg?.dados_emitente
                        }))
                    );


                    /**
                     * Preenche as permissões
                     */
                    setPermissoes([...(dados_monitorador?.permissoes || [])]?.map(p => ({
                        modulo: p.modulo,
                        permissao: p.acesso,
                    })));

                } else {
                    visible = false;
                    new Noty({
                        type: 'error',
                        timeout: 2500,
                        text: 'Falha ao carregar os dados do monitorador.'
                    }).show();
                }

            }

            if (visible) {
                setVisible(visible);
            }

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setUUID('');
            setNome('');
            setEmail('');
            setSenha('');
            setCelular('');
            setSituacao('ativo');
            setPermissoes([]);
            setEmitentes([]);
        }

        /**
         * Pesquisa
         */
        const onSearchEmitente = React.useCallback(
            debounce((input_value, callback) => {

                /**
                 * Pega os dados da api.
                 * !Não da para usar await, precisa usar o then por causa do debounce
                 */
                APIRequests.emitentes.listar({
                    query: input_value,
                    page: 1,
                    limit: 30,

                    only_self_checkout: true
                }).then(({ results }) => {
                    /**
                     * Gera a array com os novos dados.
                     */
                    const options = results.map((reg) => ({
                        // 52 é o tamanho que fica em uma linha
                        label: `${reg?.nome?.substring(0, 52)} - ${reg.cpf_cnpj}`,
                        value: reg.uuid,
                        ...reg
                    }));

                    /**
                     * Executa o callback pra mostrar os dados
                     */
                    callback(options);
                });

            }, 700),
            []
        );



        const onKeyDown = (e) => {

            // pega o código pressionado
            const code = e.which !== false ? e.which : e.keyCode;

            // stop events
            if (code == 13 || code == 27) {
                e.preventDefault();
            }

            if (code == 13) {
                onRequestConfirm();
            }

            if (code == 27) {
                onRequestClose();
            }

        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={620}

                height={620}
            >
                <Main>
                    <ModalHeader>
                        {UUID ? 'Edição' : 'Cadastro'} de Monitorador
                    </ModalHeader>

                    <Content style={{ justifyContent: 'space-between' }}>

                        <div>

                            <Row>
                                <ColSm8>
                                    <Label>
                                        Nome
                                    </Label>
                                    <InputNormal
                                        value={Nome}
                                        onKeyDown={onKeyDown}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                </ColSm8>

                                <ColSm4>
                                    <Label>Celular</Label>
                                    <InputNormal
                                        mask='phone'
                                        value={Celular}
                                        onKeyDown={onKeyDown}
                                        onChange={e => setCelular(e.target.value)}
                                    />
                                </ColSm4>
                            </Row>

                            <Row>
                                <ColSm6>
                                    <Label>Email</Label>
                                    <InputNormal
                                        value={Email}
                                        onKeyDown={onKeyDown}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </ColSm6>
                                <ColSm6>
                                    <Label>Senha</Label>
                                    <InputNormal
                                        value={Senha}
                                        onKeyDown={onKeyDown}
                                        onChange={e => setSenha(e.target.value)}
                                    />
                                </ColSm6>
                            </Row>

                            <Row>
                                <ColSm6>
                                    <Label>Todos os emitentes</Label>
                                    <SelectNormal value={TodosEmitentes} onChange={e => setTodosEmitentes(e.target.value)} onKeyDown={onKeyDown}>
                                        <option value={1}>Sim</option>
                                        <option value={0}>Não</option>
                                    </SelectNormal>
                                </ColSm6>

                                <ColSm6>
                                    <Label>Situação</Label>
                                    <SelectNormal value={Situacao} onChange={e => setSituacao(e.target.value)} onKeyDown={onKeyDown}>
                                        <option value={'ativo'}>Ativo</option>
                                        <option value={'inativo'}>Inativo</option>
                                    </SelectNormal>
                                </ColSm6>
                            </Row>

                        </div>


                        <div>


                            <Row>
                                <ColSm12>
                                    <Label>Emitentes autorizados</Label>
                                    <ReactAsyncSelect
                                        isDisabled={TodosEmitentes == 1}

                                        isMulti
                                        menuPlacement='top'

                                        loadOptions={onSearchEmitente}

                                        value={Emitentes}

                                        onChange={(data) => {
                                            // console.log(data);
                                            setEmitentes(data);
                                        }}

                                        placeholder="Digite o nome, cpf ou cnpj para localizar"

                                    />
                                </ColSm12>
                            </Row>

                            <Footer>

                                <ButtonSecondary onClick={onRequestClose}>
                                    Fechar
                                </ButtonSecondary>

                                <ButtonPrimary onClick={onRequestConfirm}>
                                    OK
                                </ButtonPrimary>

                            </Footer>

                        </div>

                    </Content>

                </Main>
            </Modal>
        )

    }
);

export default ModalCadastroEdicao;