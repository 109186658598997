import styled from 'styled-components';

const Label = styled.div`
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

    margin-bottom: 5px;
    /* margin-left: 20px;
    margin-right: 20px; */
`;

export default Label;