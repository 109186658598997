import React from 'react'

// STYLED COMPONENTS
import { Nav, Container } from "./styles";

import THEME_CONSTS from '~/consts/theme';

export default function Header({
    children
}) {
    return (
        <div style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'space-between',

            height: THEME_CONSTS.HEADER_HEIGTH,
            marginBottom: THEME_CONSTS.HEADER_MBOTTOM,

            transition: 'all 0.2s'
        }}>
            <Nav height={THEME_CONSTS.HEADER_HEIGTH} margin_bottom={THEME_CONSTS.HEADER_MBOTTOM}>
                <Container height={THEME_CONSTS.HEADER_HEIGTH}>
                    {children}
                </Container>
            </Nav>
        </div>
    )
}
