import React from 'react'

// STYLED COMPONENTS
import { Item, Container, Icon, Label, ContainerSubMenus } from "./styles";

export default function HeaderItem({
    children,

    dropdownMenuPosition = 'left',

    icon = '',
    title = '',
    color = '#fff',
    onClick = () => { }
}) {
    return (
        <Item
            className={`nav-item-n0`}
            tabIndex="-1"
            onClick={(e) => {
                e.target.focus();
                onClick();
            }}

            hasSubMenu={children ? true : false}
        >
            <Container>
                <Icon color={color} className={icon} />
                <Label color={color}>{title}</Label>
            </Container>

            {children && (
                <ContainerSubMenus className="nav-item-container-sub" dropdownMenuPosition={dropdownMenuPosition}>
                    { children}
                </ContainerSubMenus >
            )
            }
        </Item >
    )
}
