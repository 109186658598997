// API
import api from '~/services/api';

const CNPJ = async (cnpj = '') => {

    try {

        const { data: response_data } = await api.get(`/public/lambda/cnpj/${cnpj}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const CEP = async (cep = '') => {

    try {

        const { data: response_data } = await api.get(`/public/lambda/cep/${cep}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}


const LambdaRequests = {
    cnpj: CNPJ,
    cep: CEP,
};

export default LambdaRequests;