import styled from 'styled-components';

export const Thead = styled.thead`
    text-align: ${props => props.textcenter ? 'center' : 'left'};
`;
export const Tbody = styled.tbody``;
export const Tfoot = styled.tfoot``;

export const Tr = styled.tr``;

export const Th = styled.th``;
export const Td = styled.td``;

export const Table = styled.table`

    font-size: 14px;

    border: ${props => props.noborder ? '0' : '1px solid #ddd'};

    width: 100%;
    max-width: 100%;

    background-color: #fff;

    border-spacing: 0;
    border-collapse: collapse;

    thead {

        tr {
            display: table;
            width: calc(100%) !important;
            table-layout: fixed;
            user-select: none;
            -webkit-user-select: none;
        }

        tr>th {
            vertical-align: bottom !important;
            border-bottom: 2px solid #ddd;
        }

    }

    thead>tr>th, thead>tr>td,
    tbody>tr>th, tbody>tr>td {
        padding: 5px;
        line-height: 1.42857143;
        vertical-align: top;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    thead>tr:first-child>th,
    thead>tr:first-child>td {
        border-top: 0;
    }

    thead {
        tr>th:first-child {
            border-left: 0 !important;
        }
        tr>th:last-child {
            border-right: 0 !important;
        }
    }

    tbody {
        tr>td:first-child {
            border-left: 0 !important;
        }
        tr>td:last-child {
            border-right: 0 !important;
        }
    }

    thead th, thead td,
    tbody th, tbody td {
        border-left: ${props => props.noborder ? '0' : '1px solid #ddd'};;
        border-right: ${props => props.noborder ? '0' : '1px solid #ddd'};
        border-bottom: ${props => props.noline ? '0' : '1px solid #ddd'};
    }

    tbody {

        display: inline-block;
        width: 100%;

        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
            border: 0; 
        }

        tr.selected {
            background-color: #5a738d !important;
            color: #fff;
        }
    }

    tbody {

        tr {

            td {

                button {
                    margin: 0;
                    padding: 2px 8px;
                    font-size: 12px;
                    line-height: 1 !important;
                    min-width: 26px;

                    margin-left: 5px;

                    &:first-child {
                        margin-left: 0px;
                    }
                }

            }

        }

    }

    tbody>tr:nth-of-type(odd) {
        background-color: ${props => props.nostriped ? 'transparent' : '#f6f6f6'};
    }

    tbody::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }

    tbody:hover::-webkit-scrollbar-thumb {
        background-color: rgba(64, 94, 123, 1);
    }

`;
