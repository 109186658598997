import React, { useState, useEffect, useRef, useMemo } from "react";

// SERVICES
import APIRequests from "~/services/requests/painel";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import { BasePageMain, BasePageContainer, BasePageBox, BasePageTitleContainer, BasePageTitle, BasePageButtonsContainer, BasePageTopLineSeparator } from "~/components/BasePage";

// TABLE
import { Table, Thead, Tbody, Tr, Th, Td } from '~/components/Table';

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from '~/components/Buttons/Small/Primary';

// COMPONENTES INTERNOS
import ModalCadastroEdicao from "./components/ModalCadEdit";

// UTILS
import Utils from "~/utils";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageCadastroAdministradores() {

    const RefModalCad = useRef(null);

    /**
     * Lista de Administradores
     */
    const [Administradores, setAdministradores] = useState([]);

    const [Pesquisa, setPesquisa] = useState('');
    const [Pesquisar, setPesquisar] = useState(false);

    /**
     * Carrega os Administradores da
     */
    const goLoadAdministradores = async () => {
        const Administradores = await APIRequests.administradores.listar(Pesquisa);

        if (Administradores.results) {
            setAdministradores(Administradores.results);
        }
    }

    /**
     * Abre o modal para inserir.
     */
    const onRequestAddAdministrador = () => {
        RefModalCad?.current?.open();
    }

    /**
     * Abre o modal para editar.
     */
    const onRequestEditAdministrador = (uuid) => {
        RefModalCad?.current?.open(uuid);
    }

    /**
     * Ao confirmar o cadastro ou edição do Administrador.
     */
    const onConfirmCadEditAdministrador = () => {
        goLoadAdministradores();
    }

    /**
     * Carrega os Administradores a cada pesquisa
     */
    useEffect(() => {
        if (Pesquisar === true) {
            goLoadAdministradores();
        }
    }, [Pesquisa, Pesquisar]);

    /**
     * Carrega a listagem de usuários quando carregar a página.
     */
    useEffect(() => {
        goLoadAdministradores();
    }, []);

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">

                <BasePageTitleContainer>
                    <BasePageTitle>
                        Cadastro de Administradores
                    </BasePageTitle>
                </BasePageTitleContainer>

                <BasePageTopLineSeparator />

                <BasePageButtonsContainer>
                    <ButtonPrimary onClick={onRequestAddAdministrador}>
                        Adicionar
                    </ButtonPrimary>
                </BasePageButtonsContainer>

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput
                                inputType="small"
                                onChange={(e) => setPesquisar(true)}
                                onSearch={(text) => setPesquisa(text)}
                            />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table nostriped>
                            <Thead>
                                <Tr>
                                    <Th width={40}>
                                        <center>#</center>
                                    </Th>
                                    <Th>Nome</Th>
                                    <Th width={220}>E-mail</Th>
                                    <Th width={115}>Celular</Th>
                                    <Th width={100}>Situação</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Administradores.map((administrador) => {
                                    return (
                                        <Tr key={administrador.uuid}>
                                            <Td width={40}>
                                                <center>
                                                    <ButtonPrimary onClick={() => onRequestEditAdministrador(administrador.uuid)}>
                                                        <i className="fas fa-pencil" />
                                                    </ButtonPrimary>
                                                </center>
                                            </Td>
                                            <Td>
                                                {administrador.nome}

                                                {administrador.master && (
                                                    <div style={{ float: 'right' }}>
                                                        <i className="fas fa-user-crown" />
                                                    </div>
                                                )}
                                            </Td>
                                            <Td width={220}>{administrador.email}</Td>
                                            <Td width={115}>{administrador.celular}</Td>
                                            <Td width={100} style={{ textTransform: 'capitalize' }}>{administrador.situacao}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao
                ref={RefModalCad}
                onConfirmCadEdit={onConfirmCadEditAdministrador}
            />

        </BasePageMain >
    );
}
