import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from 'react';

// BOOTSTRAP
import Row from '~/components/Bootstrap/Row';
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from '~/components/Bootstrap/Col';
import InputGroup from '~/components/Bootstrap/InputGroup';
import Label from '~/components/Bootstrap/Label';

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from '~/components/Inputs/Normal/Normal';
import TextareaNormal from '~/components/Textareas/Normal';
import SelectNormal from '~/components/Selects/Normal/Normal';

// BUTONS
import ButtonPrimary from '~/components/Buttons/Normal/Primary';
import ButtonSecondary from '~/components/Buttons/Normal/Secondary';
import ButtonDanger from '~/components/Buttons/Normal/Danger';

// UTILS
import numeral from '~/utils/numeral';
import Utils from '~/utils';
import NotificacaoUtil from '~/utils/notificacao';

// SERVICES
import WebsocketService, { WS_EVENT_UPDATED } from '~/services/websockets';
import APIRequests from "~/services/requests/painel";
import ButtonSuccess from '~/components/Buttons/Normal/Success';
const ModalUnblockPDVNFCe = forwardRef(
    ({
        onConfirm = () => { }
    }, ref) => {

        const [isVisible, setVisible] = useState(false);

        const [UUID, setUUID] = useState('');
        const [Serial, setSerial] = useState('');
        const [Emitente, setEmitente] = useState('');
        const [MotivoBloqueio, setMotivoBloqueio] = useState('');

        const onRequestConfirm = async () => {

            try {

                const retorno_api = await APIRequests.pdv_nfce.desbloquear(UUID, {});

                if (retorno_api?.id) {

                    NotificacaoUtil.success({
                        msg: `PDV NFCe desbloqueado com sucesso`,
                    });

                    // fecha o modal
                    onRequestClose();

                    // dispara a função externa
                    onConfirm();

                    /**
                     * Dispara a notificação para o PDV.
                     */
                    WebsocketService.pdvs_nfce.emit(WS_EVENT_UPDATED, {
                        serial: Serial,
                    });

                } else {
                    NotificacaoUtil.error({
                        msg: `Erro ao desbloquear o PDV NFCe`,
                        timeout: 3500,
                    });
                }

            } catch (error) {
                NotificacaoUtil.error({
                    msg: error.message,
                    timeout: 3500,
                });
            }

        }

        const onRequestOpen = async (uuid = false) => {

            let visible = true;

            const dados = await APIRequests.pdv_nfce.obter(uuid);

            if (dados.uuid) {

                setUUID(dados.uuid);
                setSerial(dados.serial);
                setEmitente(dados.emitente);
                setMotivoBloqueio(dados.bloqueio_motivo);

            } else {
                visible = false;
                new Noty({
                    type: 'error',
                    timeout: 2500,
                    text: 'Falha ao carregar os dados do PDV NFCe.'
                }).show();
            }

            if (visible) {
                setVisible(visible);
            }

        }

        const onRequestClose = () => {
            setVisible(false);

            // reseta o estado
            setUUID('');
            setSerial('');
            setEmitente('');
            setMotivoBloqueio('');
        }

        /**
         * Passa a função de buscar para fora do input via ref.
         */
        useImperativeHandle(ref, () => (
            {
                open: onRequestOpen,
                close: onRequestClose
            }
        ));

        return (
            <Modal
                isVisible={isVisible}
                setVisible={onRequestClose}
                closeButtonVisible={false}
                closeOnClickOutside={false}
                width={420}
                height={385}
            >
                <Main>
                    <ModalHeader>
                        Desbloquear PDV NFCe
                    </ModalHeader>

                    <Content>

                        <Row>
                            <ColSm6>
                                <Label>Serial</Label>
                                <InputNormal
                                    disabled
                                    value={Serial}
                                />
                            </ColSm6>
                            <ColSm6>
                                <Label>Emitente</Label>
                                <InputNormal
                                    disabled
                                    value={Emitente}
                                />
                            </ColSm6>
                        </Row>

                        {/* <Row>
                            <ColSm12>
                                <Label>Data do Desbloqueio (futuro)</Label>
                                <InputNormal
                                    mask="cpf_cnpj"
                                    value={MotivoBloqueio}
                                    onKeyDown={onKeyDown}
                                    onChange={e => setMotivoBloqueio(e.target.value)}
                                />
                            </ColSm12>
                        </Row> */}

                        <Row>
                            <ColSm12>
                                <Label>Motivo do Bloqueio</Label>
                                <TextareaNormal
                                    rows={5}
                                    value={MotivoBloqueio}
                                    disabled
                                />
                            </ColSm12>
                        </Row>

                        <Footer>

                            <ButtonSecondary onClick={onRequestClose}>
                                Fechar
                            </ButtonSecondary>

                            <ButtonSuccess onClick={onRequestConfirm}>
                                Desbloquear
                            </ButtonSuccess>

                        </Footer>

                    </Content>
                </Main>
            </Modal>
        )

    }
);

export default ModalUnblockPDVNFCe;