// API
import api from '~/services/api';

import { Buffer } from 'buffer';

const Serial = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/pdvs-nfce/serial`, { responseType: 'json' });

        return response_data.serial;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Listar = async ({
    query = '',
    limit = 100,
    page = 1,

    sorted = []
}) => {

    try {

        const query_params_arr = [];

        query_params_arr.push(`query=${query}`);
        query_params_arr.push(`limit=${limit}`);
        query_params_arr.push(`page=${page}`);

        if (sorted?.length > 0) {
            const sorted_b64 = Buffer.from(JSON.stringify(sorted)).toString('base64');
            query_params_arr.push(`sorted=${sorted_b64}`);
        }

        const query_params = query_params_arr.length > 0 ? `?${query_params_arr.join('&')}` : '';

        const { data: response_data } = await api.get(`/private/pdvs-nfce${query_params}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Obter = async (uuid = '') => {

    try {

        const { data: response_data } = await api.get(`/private/pdvs-nfce/${uuid}`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Adicionar = async (dados = {}) => {

    try {

        const { data: response_data } = await api.post(`/private/pdvs-nfce`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Atualizar = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/pdvs-nfce/${uuid}`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Bloquear = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/pdvs-nfce/${uuid}/bloquear`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const Desbloquear = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/pdvs-nfce/${uuid}/desbloquear`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const LiberarInstalacao = async (uuid = '', dados = {}) => {

    try {

        const { data: response_data } = await api.put(`/private/pdvs-nfce/${uuid}/liberar-instalacao`, dados, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const PDVsNFCeRequests = {
    serial: Serial,
    listar: Listar,
    obter: Obter,
    adicionar: Adicionar,
    atualizar: Atualizar,
    bloquear: Bloquear,
    desbloquear: Desbloquear,
    liberar_instalacao: LiberarInstalacao
};

export default PDVsNFCeRequests;