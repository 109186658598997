import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/painel";

// COMPONENTES GERAIS
import SearchInput from "~/components/SearchInput";

import Row from "~/components/Bootstrap/Row";

// COMPONENTES DE BASEPAGE
import {
    BasePageMain,
    BasePageContainer,
    BasePageBox,
    BasePageTitleContainer,
    BasePageTitle,
    BasePageButtonsContainer,
    BasePageTopLineSeparator,
} from "~/components/BasePage";

// TABLE
import Table from "~/components/TableR";

// COMPONENTES GERAIS
import NavigationBar from "~/components/NavigationBar";
import ButtonPrimary from "~/components/Buttons/Small/Primary";
import ButtonDanger from "~/components/Buttons/Normal/Danger";
import ButtonSuccess from "~/components/Buttons/Normal/Success";

// COMPONENTES INTERNOS
import OnlineOffline from "./components/OnlineOffline";
import QTDOnline from "./components/QTDOnline";

import ModalLiberateSetup from "./components/ModalLiberateSetup";
import ModalCadastroEdicao from "./components/ModalCadEdit";
import ModalBlock from "./components/ModalBlock";
import ModalUnblock from "./components/ModalUnblock";

// UTILS
import Utils from "~/utils";
import { ColSm3, ColSm9 } from "~/components/Bootstrap/Col";

export default function PageCadastroSelfCheckouts() {
    const navigate = useNavigate();

    const RefModalCad = useRef(null);
    const RefModalBlock = useRef(null);
    const RefModalUnblock = useRef(null);
    const RefModalLiberateSetup = useRef(null);

    /**
     * Dados de navegação
     */
    const [NavSorted, setNavSorted] = useState([]);
    const [NavPageCount, setNavPageCount] = useState(0);
    const [NavCurrentPage, setNavCurrentPage] = useState(1);
    const [NavLimitPerPage, setNavLimitPerPage] = useState(10);
    const [NavTotalRows, setNavTotalRows] = useState(0);

    /**
     * Lista de SelfCheckouts
     */
    const [SelfCheckouts, setSelfCheckouts] = useState([]);

    const [Pesquisa, setPesquisa] = useState("");

    const [Recarregar, setRecarregar] = useState(new Date());

    /**
     * Carrega os SelfCheckouts da
     */
    const goLoadSelfCheckouts = async () => {
        const SelfCheckouts = await APIRequests.self_checkouts.listar({
            query: Pesquisa,
            page: NavCurrentPage,
            limit: NavLimitPerPage,
            sorted: NavSorted,
        });

        if (SelfCheckouts.results) {
            setSelfCheckouts(SelfCheckouts.results);
            setNavPageCount(SelfCheckouts.metadata.page.total);
            setNavTotalRows(SelfCheckouts.metadata.total);
        }
    };

    /**
     * Quando navegar na tabela
     */
    const onTableNavChange = useCallback((data) => {
        setNavSorted(data.sortBy);
        setNavLimitPerPage(data.limit);
        setNavCurrentPage(data.page + 1);
    }, []);

    /**
     * Abre o modal para inserir.
     */
    const onRequestAdd = () => {
        RefModalCad?.current?.open();
    };

    /**
     * Abre o modal para editar.
     */
    const onRequestEdit = (uuid) => {
        RefModalCad?.current?.open(uuid);
    };

    /**
     * Abre o modal de bloqueio
     */
    const onRequestBlock = (uuid) => {
        RefModalBlock?.current?.open(uuid);
    };

    /**
     * Abre o modal de desbloqueio
     */
    const onRequestUnblock = (uuid) => {
        RefModalUnblock?.current?.open(uuid);
    };

    /**
     * Abre o modal para liberar a instalação.
     */
    const onRequestLiberateSetup = (uuid) => {
        RefModalLiberateSetup?.current?.open(uuid);
    };

    /**
     * Abre o modal para inserir.
     */
    const onRequestMonitors = () => {
        navigate("/app/self-checkouts/monitoradores");
    };

    /**
     * Dispara requisições para backend.
     */
    useEffect(() => {
        let timeout = setTimeout(() => {
            goLoadSelfCheckouts();
        }, 100);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
        };
    }, [Pesquisa, NavLimitPerPage, NavCurrentPage, NavSorted, Recarregar]);

    /**
     * Refresh automatico a cada 5 segundos
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setRecarregar(new Date());
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    /**
     * Colunas da tabela
     */
    const TableColumns = useMemo(
        () => [
            {
                accessor: "uuid",
                width: 70,
                disableSortBy: true,
                Header: (data) => {
                    return <center>#</center>;
                },
                Cell: ({ value, row }) => {
                    const { original } = row;
                    return (
                        <center>
                            <ButtonPrimary disabled={!AuthService.checkPermission("self_checkouts", "editar")} onClick={() => onRequestEdit(value)}>
                                <i className="fas fa-eye" />
                            </ButtonPrimary>

                            {["instalado", "bloqueado", "bloqueio agendado"].includes(original.situacao) && (
                                <>
                                    {original.bloqueio == true ? (
                                        <ButtonSuccess
                                            disabled={!AuthService.checkPermission("self_checkouts", "desbloquear")}
                                            onClick={() => onRequestUnblock(value)}>
                                            <i className="fas fa-unlock-alt" />
                                        </ButtonSuccess>
                                    ) : (
                                        <ButtonDanger
                                            disabled={!AuthService.checkPermission("self_checkouts", "bloquear")}
                                            onClick={() => onRequestBlock(value)}>
                                            <i className="fas fa-lock-alt" />
                                        </ButtonDanger>
                                    )}
                                </>
                            )}
                        </center>
                    );
                },
            },
            {
                Header: "Serial",
                accessor: "serial",
                width: 120,
            },
            {
                Header: "Operador Logado",
                accessor: "operador_logado",
                width: 150,
            },
            {
                Header: "Versão",
                accessor: "versao",
                width: 70,
            },
            {
                Header: "Emitente",
                accessor: "emitente",
                width: 130,
            },
            {
                Header: "Emitente Nome",
                accessor: "emitente_nome",
            },
            {
                Header: "Última abertura",
                accessor: "data_ultima_abertura",
                width: 120,
            },
            {
                Header: "PDC PayGo",
                accessor: "pdc_paygo",
                width: 100,
            },
            {
                Header: "Terminal",
                accessor: "terminal",
                width: 120,
            },
            {
                Header: "Situação",
                accessor: "situacao",
                width: 90,
                Cell: ({ value, row }) => {
                    const { original } = row;

                    const permission = AuthService.checkPermission("self_checkouts", "liberar_instalacao");

                    return (
                        <div
                            style={{
                                textTransform: "capitalize",
                                cursor: original.situacao == "instalado" && permission ? "pointer" : "initial",
                            }}
                            {...(original.situacao == "instalado" && permission ? { onClick: () => onRequestLiberateSetup(original.uuid) } : {})}>
                            <OnlineOffline online={original.online} />
                            {original.situacao}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const TableInitialSortBy = [
        {
            id: "id",
            desc: true,
        },
    ];

    return (
        <BasePageMain id="main">
            <NavigationBar />

            <BasePageContainer id="container">
                <BasePageTitleContainer style={{ justifyContent: "space-between" }}>
                    <BasePageTitle>Self Checkout's</BasePageTitle>
                    <QTDOnline />
                </BasePageTitleContainer>

                {(AuthService.checkPermission("self_checkouts", "adicionar") || AuthService.checkModule("self_checkouts_monitoradores")) && (
                    <>
                        <BasePageTopLineSeparator />

                        <BasePageButtonsContainer>
                            {AuthService.checkPermission("self_checkouts", "adicionar") && (
                                <ButtonPrimary onClick={onRequestAdd}>Adicionar</ButtonPrimary>
                            )}
                            {AuthService.checkModule("self_checkouts_monitoradores") && (
                                <ButtonPrimary onClick={onRequestMonitors}>Monitoradores</ButtonPrimary>
                            )}
                        </BasePageButtonsContainer>
                    </>
                )}

                <BasePageBox id="box">
                    <Row>
                        <ColSm9 />
                        <ColSm3>
                            <SearchInput inputType="small" onSearch={(text) => setPesquisa(text)} />
                        </ColSm3>
                    </Row>

                    <div style={{ marginTop: 10 }}>
                        <Table
                            nostriped
                            initialSortBy={TableInitialSortBy}
                            pageIndex={NavCurrentPage - 1}
                            pageCount={NavPageCount}
                            totalRows={NavTotalRows}
                            initialPageSize={20}
                            data={SelfCheckouts}
                            columns={TableColumns}
                            onChangeNav={onTableNavChange}
                        />
                    </div>
                </BasePageBox>
            </BasePageContainer>

            <ModalCadastroEdicao ref={RefModalCad} onConfirmCadEdit={goLoadSelfCheckouts} />

            <ModalBlock ref={RefModalBlock} onConfirm={goLoadSelfCheckouts} />

            <ModalUnblock ref={RefModalUnblock} onConfirm={goLoadSelfCheckouts} />

            <ModalLiberateSetup ref={RefModalLiberateSetup} onConfirm={goLoadSelfCheckouts} />
        </BasePageMain>
    );
}
