/**
 * Recupera a instância do websocket
 */
const socket = window.socket;
const socket_pdv = window.socket_pdv;
const socket_self_checkout = window.socket_self_checkout;

/**
 * Eventos disponíveis
 */
export const WS_EVENT_JOIN = 'join';
export const WS_EVENT_CONNECTED = 'connected';
export const WS_EVENT_UPDATED = 'updated';
export const WS_EVENT_REQUEST_ONLINE = 'request-onlines';
export const WS_EVENT_REQUEST_SYNC_ACTION = "request-sync-action";

const WSMainOn = (channel, callback = () => { }) => {
    socket?.on(channel, callback);
    console.log('WS MAIN ON -> ' + channel);
}

const WSMainOff = (channel, callback = () => { }) => {
    socket?.off(channel, callback);
    console.log('WS MAIN OFF -> ' + channel);
}

const WSMainEmit = (event, data = {}) => {
    socket?.emit(event, data);
    console.log('WS EMIT -> ' + event);
}

const WSPDVsOn = (channel, callback = () => { }) => {
    socket_pdv?.on(channel, callback);
    console.log('WS PDVs ON -> ' + channel);
}

const WSPDVsOff = (channel, callback = () => { }) => {
    socket_pdv?.off(channel, callback);
    console.log('WS PDVs OFF -> ' + channel);
}

const WSPDVsEmit = (event, data = {}) => {
    socket_pdv?.emit(event, data);
    console.log('WS PDVs EMIT -> ' + event);
}

const WSSelfCheckoutsOn = (channel, callback = () => { }) => {
    socket_self_checkout?.on(channel, callback);
    console.log('WS Self-Checkouts ON -> ' + channel);
}

const WSSelfCheckoutsOff = (channel, callback = () => { }) => {
    socket_self_checkout?.off(channel, callback);
    console.log('WS Self-Checkouts OFF -> ' + channel);
}

const WSSelfCheckoutsEmit = (event, data = {}) => {
    socket_self_checkout?.emit(event, data);
    console.log('WS Self-Checkouts EMIT -> ' + event);
}

/**
 * Obtejo para export default
 */
const WebsocketService = {

    on: WSMainOn,
    off: WSMainOff,
    emit: WSMainEmit,

    pdvs_nfce: {
        on: WSPDVsOn,
        off: WSPDVsOff,
        emit: WSPDVsEmit,
    },

    self_checkouts: {
        on: WSSelfCheckoutsOn,
        off: WSSelfCheckoutsOff,
        emit: WSSelfCheckoutsEmit,
    },

}

export default WebsocketService;