import styled from 'styled-components';

import ButtonBase from '~/components/ButtonBase';

export default styled(ButtonBase)`
    font-size: 16px;
   
    padding: 0;
    padding: 10px 20px;
    border-radius: 4px;
`;