import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import ConfigureMaskInput, { GeraDataMaskKey } from '../mask';
import InputBaseNormal from '~/components/InputBase/normal';

const InputFormNormal = (
    {
        mask = '',
        name = '',
        ...props
    }
) => {

    const key = GeraDataMaskKey();

    const inputRef = useRef(null);

    let def_value = '';

    if (name) {

        const { fieldName, registerField, defaultValue, error } = useField(name);

        // atualiza o valor default
        def_value = defaultValue;

        useEffect(() => {

            /**
             * Configura o unform
             */
            registerField({
                name: fieldName,
                ref: inputRef.current,
                path: 'value'
            });

        }, [fieldName, registerField]);

    }

    useEffect(() => {

        /**
         * Configura as máscaras.
         */
        ConfigureMaskInput(mask, key);

    }, []);

    return (
        <>
            <InputBaseNormal
                ref={inputRef}

                {...(def_value ? { "defaultValue": def_value } : {})}

                {...(mask ? { "data-mask-key": key } : {})}
                {...props}
            />
        </>
    )

};

export default InputFormNormal;
