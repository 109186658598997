import styled from "styled-components";

const InputBase = styled.input`
    font-family: 'Roboto Condensed';
    display: block;
   
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    
    &:focus{
        border-color: #6c8fb1;
        outline: 0;
    }

    &[data-error] {
        border: 1px solid #f96f6f;
    }

    &[disabled] {
        background-color: #eee;
        cursor: not-allowed;
        filter: alpha(opacity=85);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .85;
    }
`;

export default InputBase;