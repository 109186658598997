import React from "react";
import { BrowserRouter as Router, HashRouter, MemoryRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import history from "./hooks/history";

// SERVICES
import AuthService from "~/services/auth";

// PAGES
import PageHome from "./pages/Private/Home";
import PageLogin from "./pages/Public/Login";

import PageCadastroProdutos from "./pages/Private/Cadastros/Produtos";

import PageCadastroEmitentes from "./pages/Private/Cadastros/Emitentes";
import PageCadastroAdministradores from "./pages/Private/Cadastros/Administradores";
import PageCadastroPDVsNFCe from "./pages/Private/PDVsNFCe";
import PageSelfCheckouts from "./pages/Private/SelfCheckouts/Listagem";
import PageSelfCheckoutsMonitoradores from "./pages/Private/SelfCheckouts/Monitoradores";

import PageVendas from "./pages/Private/Vendas";
import PageUsuarios from "./pages/Private/Usuarios";
import PageConfiguracoes from "./pages/Private/Configuracoes";

/**
 * Proteje as rotas privadas do sistema
 */
const PrivateRoutes = () => {

    const token = AuthService.getToken();

    if (token !== false) {
        return (
            <Routes>

                <Route path="/" element={<PageHome />} />

                <Route path="/cadastros/emitentes" element={<PageCadastroEmitentes />} />
                {/* <Route path="/cadastros/licencas" element={<PageCadastroProdutos />} /> */}
                <Route path="/cadastros/administradores" element={<PageCadastroAdministradores />} />

                <Route path="/pdvs-nfce" element={<PageCadastroPDVsNFCe />} />

                <Route path="/self-checkouts" element={<PageSelfCheckouts />} />
                <Route path="/self-checkouts/monitoradores" element={<PageSelfCheckoutsMonitoradores />} />

                {/* <Route path="/vendas" element={<PageVendas />} />
                <Route path="/usuarios" element={<PageUsuarios />} />
                <Route path="/configuracoes" element={<PageConfiguracoes />} /> */}

            </Routes>
        )
    } else {
        // retorna para o login
        return <Navigate to="/" />;
    }

};

/**
 * Rotas publicas do sistema
 */
const PublicRoutes = () => {
    // coloca o usenavigate no root
    window.useNavigate = useNavigate();

    // localização atual
    const location = useLocation();

    // verifica se já está logado
    const token = AuthService.getToken();

    // redireciona pra seção logada
    if (token !== false && location.pathname === '/') {
        // retorna para o login
        return <Navigate to="/app" />;
    }

    return (
        <Routes>
            <Route path="/" element={<PageLogin />} />
            <Route path="/app/*" element={<PrivateRoutes />} />
        </Routes>
    );
};

/**
 * Exportação padrão
 */
export default () => {
    return (
        <HashRouter history={history}>
            <PublicRoutes />
        </HashRouter>
    );
};
