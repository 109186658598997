import React, { useState, useEffect, useCallback } from "react";

// SERVICES
import WebsocketService, { WS_EVENT_CONNECTED, WS_EVENT_REQUEST_ONLINE } from '~/services/websockets';

// COMPONENTES DE BASEPAGE
import { BasePageTitle } from "~/components/BasePage";

export default function QTDPDVsOnline() {

    const [Quantidade, setQuantidade] = useState(0);

    /**
     * Quando um novo PDV se conectar.
     */
    const UpdatePDVsOnline = useCallback((data) => {
        setQuantidade(data.onlines || 0);
    }, []);

    /**
     * Registra o listener que verifica no servidor ws.
     */
    useEffect(() => {

        WebsocketService.pdvs_nfce.on(WS_EVENT_CONNECTED, UpdatePDVsOnline);

        setTimeout(() => {
            if (Quantidade == 0) {
                WebsocketService.pdvs_nfce.emit(WS_EVENT_REQUEST_ONLINE);
            }
        }, 100);

        return () => {
            WebsocketService.pdvs_nfce.off(WS_EVENT_CONNECTED, UpdatePDVsOnline);
        }
    }, []);

    return (
        <BasePageTitle>
            Online: {Quantidade}
        </BasePageTitle>
    );
}
