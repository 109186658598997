import { io } from "socket.io-client";

const url = "https://ws-painel-linvix.linvix.com";

const socket = io(url);
const socket_pdv = io(`${url}/pdvs-nfce`);
const socket_self_checkout = io(`${url}/self-checkouts`);

window.socket = socket;
window.socket_pdv = socket_pdv;
window.socket_self_checkout = socket_self_checkout;
