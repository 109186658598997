import styled from 'styled-components';

import THEME_CONSTS from '~/consts/theme';

export const Item = styled.div`
    cursor: pointer;

    border-top: 3px solid transparent;

    text-decoration: none;

    position: relative;
    display: block;
    float: left;
    height: calc( ${THEME_CONSTS.HEADER_HEIGTH} - 3px );
    min-width: 60px;

    transition: all 0.2s;

    :hover {
        background: ${THEME_CONSTS.ITEM_HOVER_COLOR};
        border-top: 3px solid ${THEME_CONSTS.TOP_LINE_COLOR};
    }
`;

export const Container = styled.div`

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    img {

        height: 40px;
        width: 40px;

    }
`;
