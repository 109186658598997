import styled from "styled-components";

export const Main = styled.div`
    font-family: "Roboto Condensed";
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
`;

export const Content = styled.div`
    width: calc(100% - (20px * 2));
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #444;
    }
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
