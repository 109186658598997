import React, { useMemo, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import debounce from "lodash/debounce";

// BOOTSTRAP
import Row from "~/components/Bootstrap/Row";
import { ColSm12, ColSm2, ColSm3, ColSm4, ColSm6, ColSm8 } from "~/components/Bootstrap/Col";
import InputGroup from "~/components/Bootstrap/InputGroup";
import Label from "~/components/Bootstrap/Label";

// COMPONENTES GERAIS
import Modal, { ModalHeader } from "~/components/Modal";
import ReactAsyncSelect from "~/components/ReactSelect/async";

// STYLED COMPONENTS
import { Main, Content, Footer } from "./styles";

// INPUTS
import InputNormal from "~/components/Inputs/Normal/Normal";
import SelectNormal from "~/components/Selects/Normal/Normal";

// BUTONS
import ButtonPrimary from "~/components/Buttons/Normal/Primary";
import ButtonSecondary from "~/components/Buttons/Normal/Secondary";

// UTILS
import numeral from "~/utils/numeral";
import Utils from "~/utils";
import NotificacaoUtil from "~/utils/notificacao";

// SERVICES
import WebsocketService, { WS_EVENT_UPDATED } from "~/services/websockets";
import APIRequests from "~/services/requests/painel";

const ModalCadastroEdicao = forwardRef(({ onConfirmCadEdit = () => {} }, ref) => {
    const [isVisible, setVisible] = useState(false);

    const [UUID, setUUID] = useState("");
    const [Serial, setSerial] = useState("");
    const [Emitente, setEmitente] = useState({});
    const [Situacao, setSituacao] = useState("liberado");
    const [PDCPaygo, setPdcPaygo] = useState("");
    const [Terminal, setTerminal] = useState("");

    const onRequestConfirm = async () => {
        try {
            const objeto_enviar = {
                serial: Serial,
                emitente: Emitente.emitente,
                emitente_uuid: Emitente.emitente_uuid,
                pdc_paygo: PDCPaygo,
                terminal: Terminal,
            };

            if (!objeto_enviar.emitente || !objeto_enviar.emitente_uuid) {
                throw new Error("Emitente não informado.");
            }

            let retorno_api = {};
            if (UUID) {
                retorno_api = await APIRequests.self_checkouts.atualizar(UUID, objeto_enviar);
            } else {
                retorno_api = await APIRequests.self_checkouts.adicionar(objeto_enviar);
            }

            if (retorno_api?.id) {
                NotificacaoUtil.success({
                    msg: UUID ? `Self Checkout atualizado com sucesso` : "Self Checkout cadastrado com sucesso",
                });

                // fecha o modal
                onRequestClose();

                // dispara a função externa
                onConfirmCadEdit();

                /**
                 * Dispara a notificação.
                 */
                WebsocketService.self_checkouts.emit(WS_EVENT_UPDATED, {
                    serial: Serial,
                });
            } else {
                NotificacaoUtil.error({
                    msg: UUID ? `Erro ao atualizar o Self Checkout` : `Erro ao cadastrar o Self Checkout`,
                    timeout: 3500,
                });
            }
        } catch (error) {
            NotificacaoUtil.error({
                msg: error.message,
                timeout: 3500,
            });
        }
    };

    const onRequestOpen = async (uuid = false) => {
        let visible = true;

        if (uuid) {
            const dados = await APIRequests.self_checkouts.obter(uuid);

            if (dados.uuid) {
                setUUID(dados.uuid);
                setSerial(dados.serial);
                setEmitente({
                    label: `${dados?.dados_emitente.nome} - ${dados?.dados_emitente.cpf_cnpj}`,
                    value: dados?.emitente,
                    emitente: dados.dados_emitente.cpf_cnpj,
                    emitente_uuid: dados.dados_emitente.uuid,
                });
                setSituacao(dados.situacao);
                setPdcPaygo(dados.pdc_paygo);
                setTerminal(dados.terminal);
            } else {
                visible = false;
                new Noty({
                    type: "error",
                    timeout: 2500,
                    text: "Falha ao carregar os dados do Self Checkout.",
                }).show();
            }
        } else {
            const serial = await APIRequests.self_checkouts.serial();

            if (serial) {
                setSerial(serial);
            } else {
                visible = false;
                new Noty({
                    type: "error",
                    timeout: 2500,
                    text: "Falha ao gerar o serial para o novo Self Checkout.",
                }).show();
            }
        }

        if (visible) {
            setVisible(visible);
        }
    };

    const onRequestClose = () => {
        setVisible(false);

        // reseta o estado
        setUUID("");
        setSerial("");
        setEmitente({});
        setSituacao("liberado");
        setPdcPaygo("");
        setTerminal("");
    };

    const onKeyDown = (e) => {
        // pega o código pressionado
        const code = e.which !== false ? e.which : e.keyCode;

        // stop events
        if (code == 13 || code == 27) {
            e.preventDefault();
        }

        if (code == 13) {
            onRequestConfirm();
        }

        if (code == 27) {
            onRequestClose();
        }
    };

    /**
     * Passa a função de buscar para fora do input via ref.
     */
    useImperativeHandle(ref, () => ({
        open: onRequestOpen,
        close: onRequestClose,
    }));

    /**
     * Função que é disparada depois de pesquisar
     */
    const goLoadOptions = React.useCallback(
        debounce((input_value, callback) => {
            /**
             * Pega os dados da api.
             * !Não da para usar await, precisa usar o then por causa do debounce
             */
            APIRequests.emitentes
                .listar({
                    query: input_value,
                    page: 1,
                    limit: 30,
                })
                .then(({ results }) => {
                    /**
                     * Gera a array com os novos dados.
                     */
                    const options = results.map((reg) => ({
                        label: `${reg.nome} - ${reg.cpf_cnpj}`,
                        value: reg.cpf_cnpj,
                        ...reg,
                    }));

                    /**
                     * Ordena pelo label (Nome - CNPJ da empresa), A->Z.
                     */
                    options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

                    /**
                     * Executa o callback pra mostrar os dados
                     */
                    callback(options);
                });
        }, 700),
        [],
    );

    return (
        <Modal isVisible={isVisible} setVisible={onRequestClose} closeButtonVisible={false} closeOnClickOutside={false} width={700} height={460}>
            <Main>
                <ModalHeader>Detalhes Self Checkout</ModalHeader>

                <Content>
                    <Row>
                        <ColSm12>
                            <Label>Serial</Label>
                            <InputNormal style={{ textAlign: "center" }} disabled value={Serial} />
                        </ColSm12>
                    </Row>

                    <Row>
                        <ColSm12>
                            <Label>Emitente</Label>
                            <ReactAsyncSelect
                                autoFocus
                                loadOptions={goLoadOptions}
                                {...(Emitente?.value
                                    ? {
                                          value: {
                                              value: Emitente?.value || "",
                                              label: Emitente?.label || "",
                                          },
                                      }
                                    : {})}
                                placeholder="Digite a razão social/nome ou CNPJ/CPF para localizar o emitente..."
                                value={Emitente}
                                onChange={(e) => {
                                    setEmitente({
                                        emitente: e.cpf_cnpj,
                                        emitente_uuid: e.uuid,
                                        value: e.value,
                                        label: e.label,
                                    });
                                }}
                            />
                        </ColSm12>
                    </Row>

                    <Row>
                        <ColSm12>
                            <Label>Situação</Label>
                            <InputNormal disabled style={{ textTransform: "captalize" }} value={Situacao} onKeyDown={onKeyDown} />
                        </ColSm12>
                    </Row>

                    <Row>
                        <ColSm6>
                            <Label>PDC PayGo</Label>
                            <InputNormal
                                style={{ textTransform: "captalize" }}
                                value={PDCPaygo}
                                onKeyDown={onKeyDown}
                                onChange={(e) => setPdcPaygo(e.target.value)}
                            />
                        </ColSm6>
                        <ColSm6>
                            <Label>Terminal</Label>
                            <InputNormal
                                style={{ textTransform: "captalize" }}
                                value={Terminal}
                                onKeyDown={onKeyDown}
                                onChange={(e) => setTerminal(e.target.value)}
                            />
                        </ColSm6>
                    </Row>

                    <Footer>
                        <ButtonSecondary onClick={onRequestClose}>Fechar</ButtonSecondary>

                        <ButtonPrimary onClick={onRequestConfirm}>OK</ButtonPrimary>
                    </Footer>
                </Content>
            </Main>
        </Modal>
    );
});

export default ModalCadastroEdicao;
