import styled from 'styled-components';
import Base from "./Base";

import THEME_CONSTS from '~/consts/theme';

const ButtonSuccess = styled(Base)`
    color: ${THEME_CONSTS.BUTTON_SUCCESS_FONT_COLOR};
    background-color: ${THEME_CONSTS.BUTTON_SUCCESS_BG_COLOR};
    border: 1px solid ${THEME_CONSTS.BUTTON_SUCCESS_BG_COLOR};
`;

export default ButtonSuccess;