import axios from "axios";

// SERVICES
import AuthService from "~/services/auth";

const api = axios.create({
    timeout: 10000,
    headers: {
        "Content-type": "application/json"
    },
});

// INTERCEPTA A CONEXÃO AXIOS
api.interceptors.request.use(
    async function (config) {

        // pega o token de autenticação
        const token = AuthService.getToken();

        const development = process.env.NODE_ENV != "production";

        if (development) {
            // configuração para homologação
            config.baseURL = `http://localhost/v1`;
        } else {
            // se estiver no modo demo não usa a api da configuração
            config.baseURL = `https://api-painel-linvix.linvix.com/v1`;
        }

        // adiciona o header
        if (token) {
            config.headers.Authorization = `Basic ${token}`;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Adicionando um interceptor de Response
api.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    // 401 é desautorizado, tem que redirecionar
    if (error.response.status === 401) {
        // força o logout
        AuthService.logout();

        // redireciona para o login
        window.useNavigate('/');
    }

    // Quaisquer códigos de status que estejam fora de 2xx fazem com que esta função seja acionada
    // Faça algo com erro de resposta
    return Promise.reject(error);
});

export default api;