// API
import api from '~/services/api';

const Listar = async () => {

    try {
        const { data: response_data } = await api.get(`/private/modulos`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const ModulosRequests = {
    listar: Listar,
};

export default ModulosRequests;