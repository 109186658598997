import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// SERVICES
import AuthService from "~/services/auth";
import APIRequests from "~/services/requests/painel";

// COMPONENTES GERAIS
import Header from '~/components/Header';
import HeaderLogo from "~/components/Header/Logo";
import HeaderItem from '~/components/Header/Item';
import HeaderItemN1 from '~/components/Header/ItemN1';

export default function NavigationBar() {
    const navigate = useNavigate();

    /**
     * Nome do usuário logado
     */
    const [NomeUsuarioLogado, setNomeUsuarioLogado] = useState('USUÁRIO');

    const onRequestHome = () => {
        navigate('/app');
    }

    const onRequestCadLicencas = () => {
        navigate('/app/cadastros/licencas');
    }

    const onRequestCadAdministradores = () => {
        navigate('/app/cadastros/administradores');
    }

    const onRequestCadEmitentes = () => {
        navigate('/app/cadastros/emitentes');
    }

    const onRequestContratos = () => {
        navigate('/app/contratos');
    }

    const onRequestAtualiacoes = () => {
        navigate('/app/atualizacoes');
    }

    const onRequestPDVsNFCe = () => {
        navigate('/app/pdvs-nfce');
    }

    const onRequestSelfCheckouts = () => {
        navigate('/app/self-checkouts');
    }

    const onRequestLogout = async () => {
        // efetua o logout
        await AuthService.logout();

        // volta pro login
        navigate('/');
    }

    const goLoadMeData = async () => {
        const me = await APIRequests.administradores.me();

        if (me.id) {
            // atualiza os dados do usuário no storage
            AuthService.setUserData(me);

            // atualiza o nome do usuário logado
            setNomeUsuarioLogado(me?.nome?.toUpperCase());
        }
    }

    const goLoadUsuarioLogado = () => {
        const usuario = AuthService.getUserData();
        setNomeUsuarioLogado(usuario?.nome?.toUpperCase());
    }

    /**
     * Carrega os endereços pendentes.
     */
    useEffect(() => {
        goLoadMeData();
        goLoadUsuarioLogado();
    }, []);

    return (
        <Header>

            <HeaderLogo onClick={onRequestHome} />

            {(AuthService.checkModule('emitentes') || AuthService.checkModule('licencas') || AuthService.checkModule('administradores')) && (
                <HeaderItem icon="fal fa-book" title='CADASTROS' onClick={() => { }}>

                    {AuthService.checkModule('emitentes') && (
                        <HeaderItemN1 title="EMITENTES" icon="fal fa-users" onClick={onRequestCadEmitentes} />
                    )}

                    {/* <HeaderItemN1 title='LICENÇAS' icon="fal fa-key" onClick={onRequestCadLicencas} /> */}

                    {AuthService.checkModule('administradores') && (
                        <HeaderItemN1 title='ADMINISTRADORES' icon="fal fa-user-crown" onClick={onRequestCadAdministradores} />
                    )}
                </HeaderItem>
            )}

            {/* <HeaderItem icon="fal fa-file-invoice" title='CONTRATOS' onClick={onRequestContratos} /> */}

            <div style={{ float: 'right' }}>

                {AuthService.checkModule('self_checkouts') && (
                    <HeaderItem title="SELF CHECKOUTS" icon="fal fa-cash-register" onClick={onRequestSelfCheckouts} />
                )}

                {AuthService.checkModule('pdvs_nfce') && (
                    <HeaderItem title="PDV'S NFC-E" icon="fal fa-desktop" onClick={onRequestPDVsNFCe} />
                )}

                {/* <HeaderItem title="ATUALIZAÇÕES" icon="fal fa-history" onClick={onRequestAtualiacoes} /> */}

                <HeaderItem icon="fal fa-user" title={NomeUsuarioLogado} onClick={() => { }} dropdownMenuPosition="right">

                    {/* <HeaderItemN1 icon="fal fa-cogs" title='CONFIGURAÇÕES' onClick={onRequestConfigs} /> */}
                    <HeaderItemN1 icon="fal fa-sign-out-alt" title='SAIR' onClick={onRequestLogout} />

                </HeaderItem>
            </div>
        </Header >
    );
}