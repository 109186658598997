import styled from 'styled-components';

import THEME_CONSTS from '~/consts/theme';

export const Item = styled.div`

    padding: 12px 20px;

    cursor: pointer;

    color: #ECF0F1;
    text-transform: uppercase;

    transition: background-color 0.3s ease;
    
    :hover {
        background: ${THEME_CONSTS.HEADER_BG_COLOR} !important;
    }

    :last-child{
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Icon = styled.i`
    width: 20px;
    text-align: center;
    font-size: 16px;
    color: ${props => props.color};
`;

export const Label = styled.span`
    width: 100%;
    font-size: 13px;
    color: ${props => props.color};
    font-weight: 500;
`;
