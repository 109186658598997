import styled from 'styled-components';

// tinha um position relative aqui, que estava passando pro cima do header
const DEFAULT_COL_SM = `
    display: block;
    // position: relative;
    min-height: 1px;

    padding-right: 10px;
    padding-left: 10px;

    box-sizing: border-box;

    float: left !important;
`;

export const ColSm12 = styled.div`
    ${DEFAULT_COL_SM}
    width: 100%;
`;
export const ColSm11 = styled.div`
    ${DEFAULT_COL_SM}
    width: 91.66666667%;
`;
export const ColSm10 = styled.div`
    ${DEFAULT_COL_SM}
    width: 83.33333333%;
`;
export const ColSm9 = styled.div`
    ${DEFAULT_COL_SM}
    width: 75%;
`;
export const ColSm8 = styled.div`
    ${DEFAULT_COL_SM}
    width: 66.66666667%;
`;
export const ColSm7 = styled.div`
    ${DEFAULT_COL_SM}
    width: 58.33333333%;
`;
export const ColSm6 = styled.div`
    ${DEFAULT_COL_SM}
    width: 50%;
`;
export const ColSm5 = styled.div`
    ${DEFAULT_COL_SM}
    width: 41.66666667%;
`;
export const ColSm4 = styled.div`
    ${DEFAULT_COL_SM}
    width: 33.33333333%;
`;
export const ColSm3 = styled.div`
    ${DEFAULT_COL_SM}
    width: 25%;
`;
export const ColSm2 = styled.div`
    ${DEFAULT_COL_SM}
    width: 16.66666667%;
`;
export const ColSm1 = styled.div`
    ${DEFAULT_COL_SM}
    width: 8.33333333%;
`;