import styled from 'styled-components';

export default styled.div`
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 10px;

    box-sizing: border-box;

    &:after {
        box-sizing: border-box;
        display: table;
        content: " ";
        clear: both;
    }

    &:before {
        box-sizing: border-box;
        display: table;
        content: " ";
    }
`;