// API
import api from '~/services/api';

const Listar = async () => {

    try {

        const { data: response_data } = await api.get(`/private/usuarios`, { responseType: 'json' });

        return response_data;

    } catch (error) {
        const response = error?.response?.data || {};

        if (response.message) {
            throw new Error(response.message);
        } else {
            throw error;
        }
    }

}

const UsuariosRequests = {
    listar: Listar,
    // obter: Obter,
    // adicionar: Adicionar,
    // atualizar: Atualizar,
};

export default UsuariosRequests;