import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import SelectBaseNormal from '~/components/SelectBase/normal';

const SelectFormNormal = (
    {
        name = '',
        ...props
    }
) => {

    const selectRef = useRef(null);

    let def_value = '';

    if (name) {

        const { fieldName, registerField, defaultValue, error } = useField(name);

        // atualiza o valor default
        def_value = defaultValue;

        useEffect(() => {

            /**
             * Configura o unform
             */
            registerField({
                name: fieldName,
                ref: selectRef.current,
                path: 'value'
            });

        }, [fieldName, registerField]);

    }

    return (
        <>
            <SelectBaseNormal
                ref={selectRef}

                {...(def_value ? { "defaultValue": def_value } : {})}

                {...props}
            />
        </>
    )

};

export default SelectFormNormal;
